import React from 'react'
import { useSelector } from 'react-redux'
import PageWrapper from '../../../components/PageWrapper'

function ChildrenProduct() {
  const category = useSelector((state) => state.app.category)
  const categoryFind = category
    .find((c) => c.id === 1)
    .columns.find((c) => c.id === 4).subCategory
  return (
    <PageWrapper
      isLinks={true}
      categoryRoute={null}
      columnRoute={'detskie'}
      subCategoryRoute={null}
      category={category}
      categoryFind={categoryFind}
      isSubCategory={true}
      categoryId={1}
      columnsId={4}
      title={'Детские товары'}
    />
  )
}
export default ChildrenProduct
